var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"item-key":"id","show-expand":"","expanded":_vm.expanded,"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":_vm.fetchDetail},scopedSlots:_vm._u([{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.dueDate)))])]}},{key:"item.receiveFromOracleAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.receiveFromOracleAt)))])]}},{key:"item.amountTtl",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.amountTtl)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('ExpandedItemList',{attrs:{"parentMenuId":item.id,"isLoading":item.fetchingDetail,"items":item.child,"colspanLength":_vm.displayedHeader.length},on:{"fetchData":_vm.fetchData}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" List Invoice From Oracle "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }