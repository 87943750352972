<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col cols="12">
          <FilterList
            :filter="filter"
            @refetch="refetch"
          />
        </v-col>
        <v-col cols="12">
          <TableList
            :filter="filter"
            :key="keyList"
            ref="tableList"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import TableList from './TableList.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'list-invoice-oracle',
  components: {
    TableList,
    FilterList,
  },
  data() {
    const receiveFromOracleStart = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
    const receiveFromOracleEnd = dayjs().format('YYYY-MM-DD');
    return {
      keyList: 0,
      filter: {
        invoiceNo: this.$route.query.invoiceNo || '',
        receiveFromOracleStart: this.$route.query.receiveFromOracleStart || receiveFromOracleStart,
        receiveFromOracleEnd: this.$route.query.receiveFromOracleEnd || receiveFromOracleEnd,
      },
    };
  },
  methods: {
    refetch() {
      this.keyList += 1;
    },
  },
};
</script>
