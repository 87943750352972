<template>
  <section>
    <v-data-table
      item-key="id"
      show-expand
      :expanded.sync="expanded"
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      @item-expanded="fetchDetail"
    >
      <template v-slot:[`item.dueDate`]={item}>
        <span>{{ dateFormat(item.dueDate) }}</span>
      </template>
      <template v-slot:[`item.receiveFromOracleAt`]={item}>
        <span>{{ dateFormat(item.receiveFromOracleAt) }}</span>
      </template>
      <template v-slot:[`item.amountTtl`]={item}>
        <span>{{ formatMoney(item.amountTtl) }}</span>
      </template>
      <template class="d-none" v-slot:expanded-item="{ headers, item }">
        <ExpandedItemList
          :parentMenuId="item.id"
          :isLoading="item.fetchingDetail"
          :items="item.child"
          :colspanLength="displayedHeader.length"
          @fetchData="fetchData"
        />
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          List Invoice From Oracle
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  dateFormat,
  formatMoney,
} from '@/helper/commonHelpers';

import ExpandedItemList from './ExpandedItemList.vue';

export default {
  name: 'table-list-role',
  components: {
    ExpandedItemList,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'Nomor Invoice',
          value: 'invoiceNo',
        },
        {
          text: 'Nama Shipper',
          value: 'companyName',
        },
        {
          text: 'Total Tagihan',
          value: 'amountTtl',
        },
        {
          text: 'Due Date',
          value: 'dueDate',
        },
        {
          text: 'Tanggal Terima (dari oracle)',
          value: 'receiveFromOracleAt',
        },
        {
          text: 'Tipe',
          value: 'transactionType',
        },
        {
          text: 'Notes',
          value: 'description',
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      expanded: [],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  methods: {
    formatMoney,
    dateFormat,
    async fetchDetail({ item, isExpanded = true }) {
      if (item.child) return;
      try {
        if (isExpanded) this.$set(item, 'fetchingDetail', true);
        if (!isExpanded) this.$root.$loading.show();
        const result = await this.$_services.oracleData.getDetailInvoiceFromOracle({ id: item.id });
        this.$set(item, 'child', result.data);
        return result;
      } finally {
        this.$delete(item, 'fetchingDetail');
        this.$root.$loading.hide();
      }
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filter,
      });
      try {
        this.isLoading = true;
        const result = await this.$_services.oracleData.getListInvoiceFromOracle({ filter });
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
